import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"

const cx = classNames.bind(styles)

const PageHeader = ({ className, title, children }) => {
  return (
    <h2 className={cx("header", className)}>
      <span className={cx("inner")} data-title={title}>
        {title ?? children}
      </span>
    </h2>
  )
}

export default PageHeader
