import React from "react"
import { graphql } from "gatsby"
import { client, isTablet } from "lib/helpers"
import SEO from "components/Seo"
import BackgroundImage from "gatsby-background-image"
import classNames from "classnames/bind"
import { Link } from "gatsby"
import PageHeader from "components/PageHeader"
import styles from "./about.module.css"
import Woods from "images/woods-icon.svg"
import PortableText from "components/PortableText"

const cx = classNames.bind(styles)

const AboutPage = ({ data }) => {
  // const body = data.sanityAbout._rawBody
  const [body, setBody] = React.useState()
  const image = data.sanityAbout.image.asset.fluid

  const query = '*[_type=="about"] { id, title, body }'

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.fetch(query)

        if (res && res.length) {
          setBody(res[0].body)
        }
      } catch (error) {
        console.error("err: ", error.message)
      }
    }

    fetchData()
  }, [])

  return (
    <>
      <SEO title={data.sanityAbout.title} />
      <div className={cx("wrapper")}>
        <div className={cx("left-side")}>
          <BackgroundImage
            className={cx("image")}
            preserveStackingContext
            Tag="div"
            fluid={image}
            backgroundColor={`#fff`}
          >
            <div className={cx("image-frame")}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </BackgroundImage>
        </div>
        <div className={cx("right-side")}>
          <Woods className={cx("content-icon")} />
          <PageHeader className={cx("header")}>
            {data.sanityAbout.title}
          </PageHeader>

          <div className={cx("content")}>
            {body && <PortableText blocks={body} />}
            {/* {body2 && <PortableText blocks={body2} />} */}

            <div className={cx("actions")}>
              <Link to="/kontaktai" className={cx("action")}>
                Susisiekti
              </Link>
              <Link to="/sklypai" className={cx("action")}>
                Rinktis Sklypą
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    sanityAbout {
      title
      _rawBody(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          _id
          fluid(maxWidth: 2500) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default AboutPage
